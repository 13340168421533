.node {
    border-radius: 5px;
    background: #f5f5f5;
    width: 150px;
    padding-bottom: 5px;
    border: #000 1px solid;
}
.nodelabel {
    padding: 3px;
    margin: 5px;
}
.viewbutton {
    width: 100%;
    display: block;
    margin-left: 0px;
    margin-bottom: 5px;
    background: #f5f5f5;
    border-radius: 0px;
    border: #fff 0px none;
    border-top: #000 1px solid;
    border-bottom: #000 1px solid;
}
.viewbuttoncontainer {
}
.viewbutton:hover {
    background: #bbb;
}

