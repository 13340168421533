.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar h1 {
    margin: 0;
}

.navbar nav a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
    padding: 8px 12px; /* Add padding for better clickability */
    border: 1px solid #fff; /* Add border around links */
    border-radius: 5px; /* Add border radius for rounded corners */
}

.navbar nav a:hover {
    background-color: #fff; /* Change background color on hover */
    color: #333; /* Change text color on hover */
}

.navbar nav a:visited {
    color: #fff; /* Change visited link color */
}
button {
    color: #333;
    padding: 1px 3px;
    border: #333 solid 2px;
    border-radius: 5px;
    background: #fff;
    margin: 5px
}
button:hover{
    background: #bbb;
}
button:disabled{
    background: #dbb;
}
select {
    color: #333;
    padding: 1px 3px;
    border: #333 solid 2px;
    border-radius: 5px;
    background: #fff;
    margin: 5px
}
input {
 padding: 2px;
    border: #333 solid 2px;
    border-radius: 5px;
}
table {
    margin-right: auto;
    margin-left: auto;
}
thead {
    font-size: medium;
    font-weight: normal;
}

.card {
    padding: 5px;
    background: #f5f5f5;
    border: #333 solid 1px;
    margin-right: 35px;
    margin-left: 35px;
    display: inline-block;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.settingpanel {
    display: inline-grid;
    margin: 20px;
    margin-right: 35px;
    margin-left: 35px;
    padding: 20px;
    border: #333 solid 2px;
    border-radius: 5px;
    background: #fff;
}

.userinfo {
    display: inline-flex;
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
}
h3 {
    margin: auto
}
body {
    background: #777;
}

.whiteText {
    color: #fff;
    font-weight: bold;
    margin: 5px;
}

.workerCard {
    display: inline-grid;
    margin: 20px;
    margin-right: 35px;
    margin-left: 35px;
    padding: 20px;
    border: #333 solid 2px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
}

.error {
    color: #fff;
    font-weight: bold;
    margin: 5px;
    background: #aa0000;
    padding: 10vh;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-container label {
    margin-right: 10px;
}

.slider-container input[type="range"] {
    margin: 0 10px;
}

hr {
    border: #333; /* Remove default border */
    height: 2px; /* Set the thickness */
    background-color: black; /* Set the color */
    margin:0px;
}
.errorcard {
    background:rgba(255,0,0,0.3);
    border: #ff0000 2px solid;
    color:rgba(230,0,0,1);
    border-radius: 3px;
    padding: 5px;
    margin:5px;
}
.infocard {
    background:rgba(30,50,255,0.3);
    border: #3355ff 2px solid;
    color:rgba(150,200,255,1);
    border-radius: 3px;
    padding: 5px;
    margin:5px;
}
.alertcard {
    background:rgba(239,230,00,0.5);
    border: #dd8511 2px solid;
    color:rgba(239,230,00,0.5);
    border-radius: 3px;
    padding: 5px;
    margin:5px;
}
.react-flow__attribution{
    display: none;
}